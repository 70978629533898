import $ from 'jquery';
import detectIE from '../../utils/detectIE';

export default class ResponsiveBackgroundImage {
  constructor(element) {
    this.element = element;
    this.imgstyles = '';
    this.img = element.querySelector('img');
    this.picture = element.querySelector('picture');
    this.hadLoadEvent = false;
    this.triggerAgain = false;
    const $pictureEl = $(this.picture);

    if ($pictureEl.data('imgstyles')) {
      this.imgstyles = $pictureEl.data('imgstyles');
    }

    this.src = '';
    if (this.img) {
      this.img.addEventListener('load', () => {
        // console.log('img load event',this.img.src);
        this.update();
      });
      if (this.img.complete) {
        // console.log('img load event complete', this.img.src);
        this.update();
      }
    }
    if (this.picture) {
      this.picture.addEventListener('load', () => {
        // console.log('picture load event');
        this.update();
      });
      if (this.picture.complete) {
        this.update();
        // console.log('picture load event complete');
      }
    }
  }

  update() {
    const src = typeof this.img.currentSrc !== 'undefined' ? this.img.currentSrc : this.img.src;
    if (this.src !== src) {
      this.src = src;
      this.element.style.cssText = this.imgstyles;
      this.element.style.backgroundImage = `url("${this.src}")`;

      if (detectIE()) {
        setTimeout(() => {
          // console.log('resize');
          const resizeEvent = window.document.createEvent('UIEvents');
          resizeEvent.initUIEvent('resize', true, false, window, 0);
          window.dispatchEvent(resizeEvent);
        }, 500);
      }
    }
  }
}
