import $ from 'jquery';

export default () => {
  $('.js-trackform').on('submit', (e) => {
    const $form = $(e.currentTarget);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'formSubmission',
      formTitle: $form.data('title'),
      formLabel: $form.data('label'),
    });
  });

  $('.js-button--track').on('click', (e) => {
    const $button = $(e.currentTarget);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'button',
      buttonTitle: $button.data('title'),
      buttonLabel: $button.data('label'),
    });
  });
};
