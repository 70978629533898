import $ from 'jquery';
import Instafeed from 'instafeed.js';

export default function () {
  const $instaFeed = $('#instafeed');
  if ($instaFeed.length) {
    const userId = $instaFeed.data('user-id');
    const accessToken = $instaFeed.data('token');
    const feed = new Instafeed({
      clientId: '13148b04ac7443129963a913c9daab67',
      get: 'user',
      limit: 3,
      resolution: 'standard_resolution',
      userId,
      template: '<div class="visual-hover-wrap grid__item medium--one-third mb-gutter"><a href="{{link}}" target="_blank"><div class="visual visual--square" style="background-image: url(\'{{image}}\')"></div></a></div>',
      accessToken,
    });
    feed.run();
  }
}
