import $ from 'jquery';

export default () => {
  $('.js-play-video').on('click', (e) => {
    const videoBlock = $(e.currentTarget).parents('.video-block');
    const $video = videoBlock.find('video');

    const video = $video[0];
    if (video.paused === true) {
      video.play();
      videoBlock.addClass('video-block--playing');
    } else {
      video.pause();
      videoBlock.removeClass('video-block--playing');
    }
  });
};
