import $ from 'jquery';
import { matchMobile } from '../../constants/defaults';

export default () => {
  const $navMainsite = $('.main-nav__wrap--main-site');
  const $navSubsiteMainsite = $('.main-nav__wrapper--subsite-zorgsaam-nav');

  $('.main-nav__trigger--main').click((e) => {
    e.preventDefault();
    $(e.currentTarget).toggleClass('active');
    $('.main-nav__wrap--main').toggleClass('active');
    $('.hamburger').toggleClass('hamburger-open');
  });
  $('.main-nav__trigger--main-site').click((e) => {
    e.preventDefault();
    $(e.currentTarget).toggleClass('active');
    $navMainsite.slideToggle();
    $('.hamburger').toggleClass('hamburger-open');
  });

  $('.main-nav__trigger--subsite-zorgsaam-nav').click((e) => {
    e.preventDefault();
    $(e.currentTarget).toggleClass('active');
    $navSubsiteMainsite.toggleClass('active');
    $('.hamburger').toggleClass('hamburger-open');
  });

  $('.main-nav__trigger--subsite').click((e) => {
    e.preventDefault();
    $(e.currentTarget).toggleClass('active');
    $(e.currentTarget).next('.page-nav-subsite__wrap').slideToggle();
  });

  matchMobile.addListener((mediaQuery) => {
    if (mediaQuery.matches) {
      $navMainsite.slideDown();
    } else {
      $navMainsite.slideUp();
    }
  });

  $('.subnav__trigger').on('click', (e) => {
    const $trigger = $(e.currentTarget);
    if ($trigger.hasClass('subnav__trigger--medium')) {
      if (matchMobile.matches) {
        window.location = $trigger.attr('href');
        return;
      }
    }

    e.preventDefault();
    const currentSubnav = $trigger.parent();
    currentSubnav.toggleClass('active');
    currentSubnav.find('.subnav').slideToggle('fast');
  });
};
