import $ from 'jquery';

export default () => {
  $('.locations-list__li').hover(
    function () { // eslint-disable-line func-names
      const $this = $(this);
      const linkedMarker = $(`.map__marker-wrap[data-location="${$this.attr('id')}"]`);
      linkedMarker.addClass('active');
    }, function () { // eslint-disable-line func-names
      const $this = $(this);
      const linkedMarker = $(`.map__marker-wrap[data-location="${$this.attr('id')}"]`);
      linkedMarker.removeClass('active');
    },
  );
};
