import $ from 'jquery';
import ResponsiveBackgroundImage from './responsiveBackgroundImage';

export default () => {
  $('.lazyload').Lazy({
    effect: 'fadeIn',
    combined: true,
    delay: 3000,
    afterLoad: (element) => {
      $(element).addClass('loaded');
      // eslint-disable-next-line no-unused-vars
      const parentElement = element.parents('.responsive-backgroundimage').get(0);
      const placeholderWrap = element.parents('.placeholder-visual-wrap').get(0);
      if (parentElement) {
        // eslint-disable-next-line no-unused-vars
        const respImgLazy = new ResponsiveBackgroundImage(parentElement);
        $(parentElement).addClass('loaded');
      }
      if (placeholderWrap) {
        $(placeholderWrap).addClass('loaded');
      }
    },
  });
};
