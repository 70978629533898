import $ from 'jquery';

export default () => {
  $('.accordion__trigger').on('click', (e) => {
    e.preventDefault();
    const $accordion = $(e.currentTarget).parent('.accordion');
    const $content = $accordion.find('.accordion__content');
    $accordion.toggleClass('active');
    $content.slideToggle('fast');
  });
};
