import $ from 'jquery';
import ResponsiveBackgroundImage from './responsiveBackgroundImage';
import detectIE from '../../utils/detectIE';

export default () => {
  $.each($('.responsive-backgroundimage:not(.lazyload)'), (index, value) => {
    const respImg = new ResponsiveBackgroundImage(value); // eslint-disable-line no-unused-vars
  });

  if (detectIE()) {
    setTimeout(() => {
      const resizeEvent = window.document.createEvent('UIEvents');
      resizeEvent.initUIEvent('resize', true, false, window, 0);
      window.dispatchEvent(resizeEvent);
    }, 2000);
  }

  $.each($('.responsive-backgroundimage'), (i, img) => {
    // eslint-disable-next-line no-unused-vars
    const respImg = new ResponsiveBackgroundImage(img);
  });
};
